import React from "react";
import styled from "styled-components";
import { Body, SubHeader } from "../Typography";
import { SiteContent } from "../Layouts";
import { ButtonPrimary, NewLink } from "../Buttons";
import { Breakpoints, Colors } from '../../theme';
 
const IndustryHeroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 30vh;
  min-height: 500px;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: bottom;
  margin-bottom: 128px;
  position: sticky;
  transform: scale(1);
  border-radius: 12px;
  margin: 32px;
  margin-top: 112px;
  /* background-color: white; */

  @media only screen and (max-width: ${Breakpoints.mobile}) {
  flex-direction: column-reverse;
  }
`;

const ContentWrapper = styled.div`
  max-width: 780px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Spacer = styled.div`
  width: 32px;
`;

const HeroH1 = styled.h1`
  font-size: 64px;
  margin: 0 0 16px 0;
  color: ${({color}) => color ?? Colors.Primary};

  @media only screen and (max-width: ${Breakpoints.tablet}) {
    font-size: 48px;
    line-height: 52px;
  }

  @media only screen and (max-width: ${Breakpoints.mobile}) {
    margin: 0 0 8px 0;
    font-size: 42px;
    line-height: 42px;
  }
`;

const IndustryHeroImage = styled.img`
  width: 40%;
  height: auto;
  @media only screen and (max-width: ${Breakpoints.mobile}) {
    width: 100%;
  }
    `;

const IndustryHero = ({ data, residential, inverted }) => {

  return (
    <IndustryHeroWrapper>
      <SiteContent column>
        <ContentWrapper>
            <SubHeader color={residential ? Colors.Residential : Colors.Commercial}>{data.subheader}</SubHeader>
            <HeroH1
              data-aos="fade-in"
              data-aos-duration={800}
              data-aos-delay="300"
              color={inverted ? Colors.White : null}
          >
            {data.title}
          </HeroH1>
            <Body 
            data-aos="fade-up"
            data-aos-duration={800}
            data-aos-delay="600"
            maxWidth={800}
            color={inverted ? Colors.White : Colors.Primary}>
              {data.body}
            </Body>
          <Spacer />

        </ContentWrapper>
        {data.buttonTitle&&
        <CTAWrapper>
          {data.buttonTitle &&
            <ButtonPrimary
              linkTo={'#Contact'}
              title={data.buttonTitle}
            />
          }
          {data.linkTitle &&
            <NewLink
            color={Colors.White}
              to={data.linkRoute}>{data.linkTitle}</NewLink>
          }
        </CTAWrapper>
      }

      </SiteContent>
      {data.heroImage && <IndustryHeroImage src={data.heroImage}></IndustryHeroImage>}
    </IndustryHeroWrapper>
  );
};

export default IndustryHero;
