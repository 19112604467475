import AudioImage from '../images/home/vastech-hairback.webp';
import SecurityImage from '../images/home/vastech-security-2.webp';
import CableImage from '../images/residential/vastech-cable.webp';
import NetworkImage from '../images/residential/vastech-network.webp';
import HeroImage from '../images/residential/vastech-hero-residential.webp'

let ResdidentialData = {
  hero: {
    title: 'Residential Audio Visual Installations.',
    subheader: "For Residential Customers",
    heroImage: HeroImage,
    body: 'It’s not easy installing a home theater or security system on your own. Fortunately, we’ve got the skills and experience to do it for you—exactly the way you want it. ',
  },

  textblocks: [
    {
      id: "audio-video",
      subheader: 'Audio & Video',
      title: 'Build the Best Home Theater on the Block.',
      body: 'It’s a man cave. It’s a she-shed. It’s the neighborhood’s best patio party. It’s whatever you want it to be! But it needs to look clean, sound great, and feel comfortable, right? If you want the best home AV setup, we can build it for you. \n\nAchieve the ultimate AV experience in any room of your home with VASTech Solutions:',
      image: AudioImage,
      items: [
        "Whole Home Audio",
        "Home Theater & Surround Sound",
        "Indoor / Outdoor",
        "Cord-Cutting Streaming Setup",
      ]
    },
    {
      id: "security",
      subheader: 'Security',
      title: 'Enhance your Home Security around the Clock.',
      body: 'You’ve spent a lifetime building the perfect living situation, and your family is your most precious asset—so why settle for simple locks or doorbells? You can do more, and we can help. \n\nStay protected in and around your home with safe and secure features you can trust:',
      image: SecurityImage,
      items: [
        "HD Video Surveillance",
        "Smart Entry Systems",
        "Motion Sensors & Alarms",
        "Smart Automation",
      ]
    },
    {
      subheader: 'Full Scale Services',
      title: 'See What You Want. Love What You See.',
      id: "cabling",
      body: 'Your home AV installation needs to be structurally sound, reliable, and secure. But it also needs to look perfect. When unruly wires are the worst, VASTech Solutions is the best.',
      image: CableImage,
      items: [
        "High-Quality Audio & Video",
        "Structured Cabling",
        "New Home Planning & Wiring",
        "Preventative Maintenance",
        "Extended System Life",

      ]
    },
    {
      subheader: 'Home Automation & Networking',
      title: 'Say the Word and It’s Yours.',
      id: "networking",
      body: 'Smart home systems make life easier—it’s as simple as that. Comfort comes by way of convenience, which is great news for you and us, because we can provide both.',
      image: NetworkImage,
      items: [
        "Custom Lighting",
        "Pro & Mesh Wi-Fi",
        "HVAC / Temperature Control",
        "Voice Assistance",

      ]
    }
  ],
};

export { ResdidentialData };
